import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const FooterLink = ({
  url,
  label,
  cookies,
  external,
  className = '',
  ...rest
}) =>
  external ? (
    <a
      href={url}
      target="_blank"
      rel="nofollow noreferrer"
      className={className}
      {...rest}
    >
      {label}
    </a>
  ) : (
    <Link
      {...rest}
      to={url}
      className={cookies ? 'ot-sdk-show-settings' : className}
    >
      {label}
    </Link>
  );

FooterLink.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  cookies: PropTypes.bool,
  external: PropTypes.bool,
  className: PropTypes.string,
};

export default FooterLink;
