import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import LiveGradient from './footer/liveGradient';
import FooterLink from './footer/link';
import StoreButtons from '../common/storeButtons';

const Footer = ({ campaign }) => {
  const { siteMetaMarkdown, footerItemsMarkdown } = useStaticQuery(graphql`
    {
      siteMetaMarkdown: markdownRemark(
        fileAbsolutePath: { regex: "/settings/site-meta.md/" }
      ) {
        frontmatter {
          title
          logo {
            childImageSharp {
              fixed(width: 108, height: 32, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          logoAlt {
            childImageSharp {
              fixed(width: 108, height: 32, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      footerItemsMarkdown: markdownRemark(
        fileAbsolutePath: { regex: "/settings/footer-items.md/" }
      ) {
        frontmatter {
          productItems {
            label
            url
          }
          resourcesItems {
            label
            url
            external
          }
          legalItems {
            label
            url
            cookies
            external
          }
          socialItems {
            social
            url
            icon {
              childImageSharp {
                fixed(width: 40, height: 40, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `);

  const siteMeta = siteMetaMarkdown.frontmatter;
  const siteTitle = siteMeta.title;
  const siteLogo = siteMeta.logo.childImageSharp.fixed;
  const siteLogoAlt = siteMeta.logoAlt.childImageSharp.fixed;
  const productItems = footerItemsMarkdown.frontmatter.productItems;
  const resourcesItems = footerItemsMarkdown.frontmatter.resourcesItems;
  const legalItems = footerItemsMarkdown.frontmatter.legalItems;
  const socialItems = footerItemsMarkdown.frontmatter.socialItems;

  return (
    <>
      <LiveGradient>
        <section className="container space-around">
          <div className="max-w-content">
            <h2 className="text-title contrast-high mb-4 md:mb-8">
              Ready to start editing with Splice?
            </h2>
            <p className="text-long-lead max-w-secondary-content">
              Join more than 70 million delighted Splicers. Download Splice
              video editor now, and share stunning videos on social media within
              minutes!
            </p>

            {/* Store Buttons */}
            <StoreButtons
              campaign={`${campaign}-footer`}
              className="mt-10 md:mt-12 lg:mt-16 items-start"
            />
          </div>
        </section>
      </LiveGradient>

      <footer className="footer container space-around">
        <div className="footer__brand mb-6 md:mb-12">
          {siteLogo && siteLogoAlt ? (
            <>
              <GatsbyImage
                className="hidden dark:block"
                fixed={siteLogo}
                alt="Splice"
              />
              <GatsbyImage
                className="dark:hidden"
                fixed={siteLogoAlt}
                alt="Splice"
              />
            </>
          ) : (
            siteTitle
          )}
          <p className="M16 mt-4 md:mt-8">
            © {new Date().getFullYear()} AI Creativity S.r.l.
            <br />
            All rights reserved.
          </p>
        </div>

        <div className="footer__socials mb-8 md:mb-12">
          <h3 className="SB20 md:SB24 mb-4 md:mb-8">Follow us</h3>
          <div className="flex space-x-2">
            {socialItems.map((item) => (
              <a
                className="w-10 h-10"
                href={item.url}
                target="_blank"
                rel="noreferrer"
                key={item.social}
              >
                <GatsbyImage
                  className="w-full h-full transition-opacity opacity-70 hover:opacity-100 filter-invert dark:filter-none"
                  fixed={item.icon.childImageSharp.fixed}
                  alt={item.social}
                />
              </a>
            ))}
          </div>
        </div>

        <div className="footer__links grid grid-cols-2 gap-y-6 md:flex md:justify-between">
          <div className="max-w-sixth-column">
            <h3 className="SB20 md:SB24 mb-4 md:mb-8">Product</h3>
            <ul className="space-y-2">
              {productItems.map((item) => (
                <li key={item.label}>
                  <FooterLink
                    {...item}
                    className="M16 md:M18 transition-colors duration-300 dark:hover:text-white hover:text-gray-darker"
                  />
                </li>
              ))}
            </ul>
          </div>

          <div className="max-w-sixth-column">
            <h3 className="SB20 md:SB24 mb-4 md:mb-8">Resources</h3>
            <ul className="space-y-2">
              {resourcesItems.map((item) => (
                <li key={item.label}>
                  <FooterLink
                    {...item}
                    className="M16 md:M18 transition-colors duration-300 dark:hover:text-white hover:text-gray-darker"
                  />
                </li>
              ))}
            </ul>
          </div>

          <div className="max-w-sixth-column">
            <h3 className="SB20 md:SB24 mb-4 md:mb-8">Legal</h3>
            <ul className="space-y-2">
              {legalItems.map((item) => (
                <li key={item.label}>
                  <FooterLink
                    {...item}
                    className="M16 md:M18 transition-colors duration-300 dark:hover:text-white hover:text-gray-darker"
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </footer>

      <div className="container max-w-hero text-center pb-6">
        <p className="R14 md:R16 leading-loose opacity-50">
        Copyright © AI Creativity S.r.l. | Via Nino Bonnet 10, 20154 Milan, Italy | VAT, tax code, and number of registration with the Milan Monza Brianza Lodi Company Register 13250480962 | REA number MI 2711925 | Contributed capital €100,000.00 | Sole shareholder company subject to the management and coordination of Bending Spoons S.p.A.
        </p>
      </div>
    </>
  );
};

Footer.propTypes = {
  campaign: PropTypes.string,
};

export default Footer;
